import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Link from '../../components/Link';

import { getImages } from '../../utils/common';

const Container = styled(Layout)`
  max-width: 100%;
`;

const Image1 = styled(Img)`
  max-width: 100%;
`;

const Button = styled(Link)`
  position: absolute;
  left: 50%;
  width: 21%;
  height: 1.5%;
  background-color: red;
  z-index: 100;
  transform: translateX(-50%);
  opacity: 0;
  &.btn1{
    top: 49.6%;
  }
  &.btn2{
    top: 95.2%;
  }
  &.btn3{
    top: 18%;
		left: 16%;
  }
`;


const useImageData = () => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "images/edm" } }) {
        nodes {
          name
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return allFile.nodes;
};

const Edm202109TSDayVIP = () => {
  const images = getImages(useImageData());

  return (
    <Layout padding={false}>
      <div className="position-relative">
        <Button className="btn1" to={'https://bit.ly/3mn3NcC'} />
        <Button className="btn2" to={'https://bit.ly/3mn3NcC'} />
        <Button className="btn3" to={'https://bit.ly/3msvtwH'} />
        <Image1 fluid={images['Biotherm-Extra-VIP-HK-14Sep']} />
      </div>
    </Layout>
  );
};

export default Edm202109TSDayVIP;
